.App {
  text-align: center;
  height: 100vh;
}

@media (min-width: 576px) {
  .App {
    border: 1px solid;
    border-radius: .5em;
    max-width: 40em;
    margin: auto;
  }
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
